<template>
    <div id="app">
        <el-header style="padding: 0">
            <div>
                <router-link to="/">
                    <el-image :src="logo" class="logo">
                    </el-image>
                </router-link>
                <el-menu router class="topBar" text-color="#fff" active-text-color="#fff" background-color="#7788FA"
                         :default-active="activeIndex" mode="horizontal">
                    <el-menu-item index="/">
                        首页
                    </el-menu-item>
                    <!--                    <el-menu-item index="buy">套餐购买</el-menu-item>-->
                    <!--                    <el-menu-item index="3"><span class="menu-item">专业技术服务</span></el-menu-item>-->
                    <div class="login-register-container">
                        <el-button type="success" @click="buyShow=true" size="mini" style="margin-right: 11px">
                            购买套餐
                        </el-button>
                        <div v-if="userInfo.id">
                            <el-button type="primary" size="mini" @click="goCenter">个人中心</el-button>
                            <el-button size="mini" @click="logout">登出</el-button>
                        </div>
                        <div v-else>
                            <el-button type="success" size="mini" plain @click="showLogin">登录</el-button>
                            <el-button size="mini" plain @click="showRegister">注册</el-button>
                        </div>
                    </div>
                </el-menu>
            </div>
            <el-dialog
                title="登录/注册"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                width="30%">
                <div>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="登录" name="login">
                            <el-form :model="loginForm" label-position="left" label-width="80px">
                                <el-form-item label="手机号">
                                    <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
                                </el-form-item>
                                <el-form-item label="密码" v-show="!smsLoginCheckBox">
                                    <el-input v-model="loginForm.password" type="password"
                                              placeholder="请输入密码"></el-input>
                                </el-form-item>
                                <el-form-item label="验证码" v-show="smsLoginCheckBox">
                                    <div class="container">
                                        <el-input v-model="loginForm.smsCode" clearable class="input"
                                                  placeholder="请输入验证码"></el-input>
                                        <el-button class="button" :disabled="counting" @click="getSmsCode"
                                                   type="primary">{{
                                                counting ? `${count} 秒后重新获取` : '获取验证码'
                                            }}
                                        </el-button>
                                    </div>
                                </el-form-item>
                                <el-checkbox v-model="smsLoginCheckBox" @change="changeLoginType">验证码登录
                                </el-checkbox>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="注册" name="register">
                            <el-form :model="loginForm" label-position="left" label-width="80px">
                                <el-form-item label="手机号">
                                    <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
                                </el-form-item>
                                <el-form-item label="密码">
                                    <el-input v-model="loginForm.password" type="password"
                                              placeholder="请输入密码"></el-input>
                                </el-form-item>
                                <el-form-item label="验证码">
                                    <div class="container">
                                        <el-input v-model="loginForm.smsCode" clearable class="input"
                                                  placeholder="请输入验证码"></el-input>
                                        <el-button class="button" :disabled="counting" @click="getSmsCode"
                                                   type="primary">{{
                                                counting ? `${count} 秒后重新获取` : '获取验证码'
                                            }}
                                        </el-button>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                    <div style="margin-top: 10px">
                        <span>登录\注册即代表同意</span>
                        <a href="flsm.html" target="_blank">《法律声明》</a>
                        <a href="fwtk.html" target="_blank">《飞鱼网站服务条款》</a>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" :loading="loading" @click="submit">确 定</el-button>
                </span>
            </el-dialog>
        </el-header>
        <router-view/>
        <el-dialog
            title="购买/充值"
            :visible.sync="buyShow"
            :close-on-click-modal="false"
            width="80%">
            <el-tabs type="border-card" v-model="buyTabsName">
                <el-tab-pane label="购买IP" name="buy">
                    <BuyIp v-if="buyShow"></BuyIp>
                </el-tab-pane>
                <el-tab-pane label="充值" name="recharge">
                    <Buy></Buy>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button @click="buyShow = false">取 消</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import logo from './assets/logo/logo-1.png'
import {tansParams} from "@/api/utils";
import Buy from "@/components/Buy.vue";
import BuyIp from "@/components/BuyIp.vue";
import {EventBus} from "@/api/eventBus";

export default {
    name: 'app',
    data() {
        return {
            loading: false,
            buyShow: false,
            smsLoginCheckBox: false,
            logo,
            activeName: 'login',
            buyTabsName: 'buy',
            activeIndex: '1',
            dialogVisible: false,
            loginForm: {
                phone: '',
                password: '',
                smsCode: '',
                loginType: 'ACCOUNT_LOGIN',
                smsType: 'LOGIN',
            },
            count: 60,
            counting: false,
            timer: null
        };
    },
    watch: {
        activeName: function () {
            this.loginForm.smsType = this.activeName === 'login' ? 'LOGIN' : 'REGISTER'
        }
    },
    created() {
        this.getSystemConfig()
        this.$on('no_login', () => {
            this.showLogin()
        })
        EventBus.$on('openBuyIp', () => {
            this.buyShow = true
        });
        EventBus.$on('openRecharge', () => {
            this.buyTabsName = 'recharge'
            this.buyShow = true
        });
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null
    },
    computed: {
        userInfo: function () {
            let userInfoStr = localStorage.getItem('userInfo');
            if (!userInfoStr) {
                return {}
            }
            return JSON.parse(userInfoStr)
        }
    },
    components: {BuyIp, Buy},
    methods: {
        logout() {
            localStorage.clear()
            location.reload()
            location.href = '/'
        },
        goCenter() {
            this.$router.push('/center')
        },
        showLogin() {
            this.activeName = 'login'
            this.dialogVisible = true;
        },
        showRegister() {
            this.activeName = 'register'
            this.dialogVisible = true;
        },
        changeLoginType(args) {
            if (args) {
                this.loginForm.loginType = 'SMS_LOGIN'
            } else {
                this.loginForm.loginType = 'ACCOUNT_LOGIN'
            }
        },
        startCountdown() {
            if (this.counting) {
                return;
            }
            this.counting = true;
            this.timer = setInterval(() => {
                if (this.count > 0) {
                    this.count--;
                } else {
                    this.stopCountdown();
                }
            }, 1000);
        },
        stopCountdown() {
            clearInterval(this.timer);
            this.count = 60;
            this.counting = false;
        },
        getUserInfo() {
            this.$http.get('/feiyu/ip/info')
                .then(res => {
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    location.reload()
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        doLogin() {
            this.loading = true
            this.$http.post('/feiyu/login/login?' + tansParams(this.loginForm))
                .then(res => {
                    console.log('res=>>', res)
                    localStorage.setItem('token', res.data)
                    this.getUserInfo()
                    this.$message.success('登录成功')
                    this.dialogVisible = false
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        doRegister() {
            this.loading = true
            this.$http.post('/feiyu/login/register?' + tansParams(this.loginForm))
                .then(res => {
                    this.$message.success('注册成功,请登录')
                    this.activeName = 'login'
                    this.stopCountdown();
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        getSmsCode() {
            this.$http.post('/feiyu/login/sendSms?' + tansParams(this.loginForm))
                .then(res => {
                    this.$message.success('发送成功')
                    this.startCountdown()
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        submit() {
            if (this.activeName === 'login') {
                this.doLogin()
            } else {
                this.doRegister()
            }
            //
            // this.$message('提交成功');
            // this.dialogVisible = false;
            // setTimeout(()=>{
            //     this.activeIndex = undefined
            //     this.$router.push('/center')
            // },1000)
        },
        getSystemConfig() {
            this.$http.post('/feiyu/login/getSystemConfig')
                .then(res => {
                    localStorage.setItem('systemConfig', JSON.stringify(res.data))
                })
        },
    }
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
}

#app {
    //font-family: 'Avenir', Helvetica, Arial, sans-serif;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    //text-align: center;
    //color: #2c3e50;
    //margin-top: 60px;

}

.container {
    display: flex;
    align-items: center;
}

.input {
    flex: 1;
    margin-right: 10px;
}

.button {
    margin-left: 10px;
}

.logo {
    float: left;
    width: 85px;
    height: auto;
    z-index: 9999;
    margin-top: 17px;
    margin-left: 55px;
    margin-right: 55px;
    cursor: pointer;
}

.topBar {
    background: linear-gradient(to right, #7788FA, #3F37D8);
}

.menu-item {
    text-decoration: none;
}

.login-register-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-top: 8px;
    padding: 10px;

}

/* WebKit浏览器（如Chrome和Safari） */
::-webkit-scrollbar {
    width: 10px; /* 滚动条宽度 */
}

::-webkit-scrollbar-track {
    background: #fff; /* 滚动条轨道背景色 */
}

::-webkit-scrollbar-thumb {
    background: #7685F8; /* 滚动条滑块颜色 */
}

/* Firefox浏览器 */
* {
    scrollbar-width: thin; /* 滚动条宽度 */
}

* {
    scrollbar-color: #7685F8 #fff; /* 滚动条滑块颜色和轨道背景色 */
}

</style>
