<template>
    <div>
        <div class="main">
            <div class="item" v-for="item in list">
                <span class="badge">多送{{ jisuan(item) }}</span>
                <div class="rechargeAmt"><span style="font-size: 14px;margin-right: 4px">¥</span>{{ item.rechargeAmt }}
                </div>
                <div class="actualAmt">实到{{ item.actualAmt }}鱼币</div>

                <div style="margin-top: 20px"></div>
                <el-button size="medium" type="danger" class="buy-btn" round @click="handleBuy(item)">立即购买</el-button>

            </div>
        </div>
        <el-dialog
            title="支付"
            append-to-body
            :visible.sync="show">
            <TradeType v-if="show" :canUseBalance="false" ref="tradeType" @pay="buy" @paySuccess="paySuccess" @close="show=false"></TradeType>
        </el-dialog>
    </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import TradeType from "@/components/TradeTypeSelect.vue";

export default {
    name: 'Buy',
    components: {TradeType, Footer},
    data() {
        return {
            show: false,
            list: [],
            currentItem:undefined,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.$http.get('/feiyu/recharge/list')
                .then(res => {
                    this.list = res.rows
                }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        handleBuy(item){
            this.show = true
            this.currentItem = item
        },
        buy(tradeType) {
            this.$http.post('/feiyu/recharge/recharge?tradeType='+tradeType+'&rechargeAmt='+this.currentItem.rechargeAmt)
                .then(res => {
                    this.$message.success('下单成功,请及时支付')
                    this.$refs.tradeType.generateQRCode(res.data.codeUrl)
                    this.$refs.tradeType.amt = res.data.totalPrice
                    this.$refs.tradeType.loading = false
                    this.$refs.tradeType.startInterval(res.data.orderId)
                }).catch(err => {
                this.$message.error(err.msg)
                this.$refs.tradeType.loading = false
            })
        },
        paySuccess(){
            this.$message.success('支付成功')
            this.show = false
        },
        jisuan(item) {
            return (((Number(item.actualAmt) - Number(item.rechargeAmt)) / Number(item.rechargeAmt)) * 100).toFixed(0) + "%"

        }
    }
}
</script>

<style scoped>
.main {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 定义三列，每列宽度相等 */
    gap: 20px; /* 设置项目之间的间距 */
}

.item {
    position: relative;
    background: #7786F9;
    padding: 20px;
    z-index: 10;
    border-radius: 10px;
    text-align: center;
    color: #fff;
}

.badge {
    position: absolute;
    font-size: 12px;
    top: 0;
    right: 0;
    background: red;
    padding: 4px 8px;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;

}

.actualAmt {
    font-size: 14px;
    color: #F4F4F5;
}

.buy-btn {
    background: linear-gradient(to right, #E25426, #D7303B);
}

.rechargeAmt {
    margin-top: 14px;
    font-size: 24px;
}

</style>
